import React from 'react';
import {ConfigProvider} from 'antd';
import App from './app';
import it_IT from 'antd/lib/locale-provider/it_IT';
import en_US from 'antd/lib/locale-provider/en_GB';
import {message} from 'antd';

message.config({
    maxCount: 1
});

export default ({element}) => (
    <ConfigProvider locale={it_IT}>
        <App>
            {element}
        </App>
    </ConfigProvider>
);
