import React from 'react';
import {ConfigProvider} from 'antd';
import it_IT from 'antd/es/locale/it_IT';
import en_US from 'antd/es/locale/en_US';

class App extends React.Component {

    render() {
        return (
            <ConfigProvider locale={it_IT}>
                {this.props.children}
            </ConfigProvider>
        );
    }
}

export default App;
