// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-holes-js": () => import("./../../../src/pages/holes.js" /* webpackChunkName: "component---src-pages-holes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prizes-js": () => import("./../../../src/pages/prizes.js" /* webpackChunkName: "component---src-pages-prizes-js" */),
  "component---src-pages-sponsor-1-bancastato-js": () => import("./../../../src/pages/sponsor/1-bancastato.js" /* webpackChunkName: "component---src-pages-sponsor-1-bancastato-js" */),
  "component---src-pages-sponsor-10-mino-caggiula-architects-js": () => import("./../../../src/pages/sponsor/10-mino-caggiula-architects.js" /* webpackChunkName: "component---src-pages-sponsor-10-mino-caggiula-architects-js" */),
  "component---src-pages-sponsor-11-bmw-emil-frey-js": () => import("./../../../src/pages/sponsor/11-bmw-emil-frey.js" /* webpackChunkName: "component---src-pages-sponsor-11-bmw-emil-frey-js" */),
  "component---src-pages-sponsor-12-newline-js": () => import("./../../../src/pages/sponsor/12-newline.js" /* webpackChunkName: "component---src-pages-sponsor-12-newline-js" */),
  "component---src-pages-sponsor-13-bazzana-js": () => import("./../../../src/pages/sponsor/13-bazzana.js" /* webpackChunkName: "component---src-pages-sponsor-13-bazzana-js" */),
  "component---src-pages-sponsor-14-kartell-js": () => import("./../../../src/pages/sponsor/14-kartell.js" /* webpackChunkName: "component---src-pages-sponsor-14-kartell-js" */),
  "component---src-pages-sponsor-15-delco-js": () => import("./../../../src/pages/sponsor/15-delco.js" /* webpackChunkName: "component---src-pages-sponsor-15-delco-js" */),
  "component---src-pages-sponsor-16-aclub-js": () => import("./../../../src/pages/sponsor/16-aclub.js" /* webpackChunkName: "component---src-pages-sponsor-16-aclub-js" */),
  "component---src-pages-sponsor-17-smartgorla-js": () => import("./../../../src/pages/sponsor/17-smartgorla.js" /* webpackChunkName: "component---src-pages-sponsor-17-smartgorla-js" */),
  "component---src-pages-sponsor-18-wetag-js": () => import("./../../../src/pages/sponsor/18-wetag.js" /* webpackChunkName: "component---src-pages-sponsor-18-wetag-js" */),
  "component---src-pages-sponsor-2-fratelli-beretta-js": () => import("./../../../src/pages/sponsor/2-fratelli-beretta.js" /* webpackChunkName: "component---src-pages-sponsor-2-fratelli-beretta-js" */),
  "component---src-pages-sponsor-3-clovis-sail-yacht-js": () => import("./../../../src/pages/sponsor/3-clovis-sail-yacht.js" /* webpackChunkName: "component---src-pages-sponsor-3-clovis-sail-yacht-js" */),
  "component---src-pages-sponsor-4-bomio-sa-elettrigila-js": () => import("./../../../src/pages/sponsor/4-bomio-sa-elettrigila.js" /* webpackChunkName: "component---src-pages-sponsor-4-bomio-sa-elettrigila-js" */),
  "component---src-pages-sponsor-5-swica-js": () => import("./../../../src/pages/sponsor/5-swica.js" /* webpackChunkName: "component---src-pages-sponsor-5-swica-js" */),
  "component---src-pages-sponsor-6-consulca-sa-js": () => import("./../../../src/pages/sponsor/6-consulca-sa.js" /* webpackChunkName: "component---src-pages-sponsor-6-consulca-sa-js" */),
  "component---src-pages-sponsor-7-yeldo-js": () => import("./../../../src/pages/sponsor/7-yeldo.js" /* webpackChunkName: "component---src-pages-sponsor-7-yeldo-js" */),
  "component---src-pages-sponsor-8-zurich-js": () => import("./../../../src/pages/sponsor/8-zurich.js" /* webpackChunkName: "component---src-pages-sponsor-8-zurich-js" */),
  "component---src-pages-sponsor-9-kurz-js": () => import("./../../../src/pages/sponsor/9-kurz.js" /* webpackChunkName: "component---src-pages-sponsor-9-kurz-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */)
}

